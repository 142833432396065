import React from 'react'
import CardPanel from '../card/panel'
import { BoxDetail, BoxWrapper } from '../box'
import { SearchForm2 } from './manage-event/share'
import CardCarousel from '../card/card-carousel'

const dropdownItems = locale => {
  return [
    { id: 'title_en', title: locale.LABEL_INFO_TITLE_ENG },
    { id: 'title_th', title: locale.LABEL_INFO_TITLE_TH },
    { id: 'description_en', title: locale.LABEL_INFO_DESC_ENG },
    { id: 'description_th', title: locale.LABEL_INFO_DESC_TH },
  ]
}

export default props => (
  <BoxWrapper>
    {/* <BoxDetail style={{ paddingTop: 10 }}>
      <div style={{ margin: '10px 0px 10px 15px', background: '#ffffff', borderRadius: 10, width: 286 }}>
        <SearchForm2
          data={dropdownItems(props.locale)}
          keyword={props.keyword}
          keywordType={props.keywordType}
          onKeyword={props.onChangeKeyword}
          onKeywordType={props.onChangeKeywordType}
          onSearch={props.onSubmit}/>
      </div>
    </BoxDetail> */}
    {props.data.upcoming_list.length > 0 && (
      <BoxDetail>
        <CardPanel
          title={props.locale.UPCOMING_EVENT}
          onSeeAll={props.onGoUpcoming}
          locale={props.locale}
        >
          <CardCarousel
            className="is-card-slider"
            data={props.data.upcoming_list}
            dataMobile={props.data.upcoming_list_mobile}
            onShare={id => props.onShare(id)}
            onGoDetail={id => props.onGoDetail(id)}
            locale={props.locale}
          />
        </CardPanel>
      </BoxDetail>
    )}

    {props.data.joined_list.length > 0 && (
      <BoxDetail>
        <CardPanel
          title={props.locale.JOINED}
          onSeeAll={props.onGoJoined}
          locale={props.locale}
        >
          <CardCarousel
            className="is-card-slider"
            data={props.data.joined_list}
            dataMobile={props.data.joined_list_mobile}
            onShare={id => props.onShare(id)}
            onGoDetail={id => props.onGoDetail(id)}
            locale={props.locale}
          />
        </CardPanel>
      </BoxDetail>
    )}

    {props.data.history_list.length > 0 && (
      <BoxDetail>
        <CardPanel
          title={props.locale.HISTORY}
          onSeeAll={props.onGoHistory}
          locale={props.locale}
        >
          <CardCarousel
            className="is-card-slider"
            data={props.data.history_list}
            dataMobile={props.data.history_list_mobile}
            onShare={id => props.onShare(id)}
            onGoDetail={id => props.onGoDetail(id)}
            locale={props.locale}
          />
        </CardPanel>
      </BoxDetail>
    )}

    {props.data.public_list.length > 0 && (
      <BoxDetail>
        <CardPanel
          title={props.locale.PUBLIC}
          onSeeAll={props.onGoPublic}
          locale={props.locale}
        >
          <CardCarousel
            className="is-card-slider"
            data={props.data.public_list}
            dataMobile={props.data.public_list_mobile}
            onShare={id => props.onShare(id)}
            onGoDetail={id => props.onGoDetail(id)}
            locale={props.locale}
          />
        </CardPanel>
      </BoxDetail>
    )}
  </BoxWrapper>
)
