import React from 'react'
import Link from 'gatsby-link'
import { navigate } from 'gatsby'
import s from 'styled-components'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ButtonToTop from '../btn-to-top'
import { SearchForm2 } from '../presentation/manage-event/share'
import LogoImage from '../../images/logo.svg'
import homeActions from '../../state/home/actions'

/* eslint-disable no-undef,jsx-a11y/anchor-is-valid */
const InputSearch = s.input`background: #eaeaea;padding: 0 5px;transition: width .35s ease-in-out;`

function goToPrice() {
  $('html, body').animate({ scrollTop: $('#price-table').offset().top }, 500)
}

const Logo = () => {
  return (
    <div className="pull-left logo-box">
      <div className="logo">
        <Link to="/home">
          <img src={LogoImage} alt="" />
        </Link>
      </div>
    </div>
  )
}

const Menu = () => {
  return (
    <div className="pull-right logo-box">
      <div className="logo">
        <SearchForm2 />
      </div>
    </div>
  )
}

class HeaderLayoutSearch extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    $(window).on('scroll', () => {
      this.headerStyle()
    })
  }

  //  Update Header Style and Scroll to Top
  headerStyle() {
    if ($('.corpo-header').length) {
      const windowpos = $(window).scrollTop()
      const siteHeader = $('.corpo-header')
      const stickyHeader = $('.sticky-header')
      const scrollLink = $('.scroll-top')
      if (windowpos >= 200) {
        siteHeader.addClass('fixed-header')
        stickyHeader.css('visibility', 'visible')
        stickyHeader.css('opacity', '1')
        scrollLink.css('opacity', '1')
      } else {
        siteHeader.removeClass('fixed-header')
        stickyHeader.css('visibility', 'hidden')
        stickyHeader.css('opacity', '0')
        scrollLink.css('opacity', '0')
      }
    }
  }

  render() {
    const NavMenu = () => (
      <div className="default-container">
        <div className="clearfix">
          <Logo />
          {/* <Menu /> */}
        </div>
      </div>
    )

    return (
      <header className="corpo-header">
        <div className="header-upper">
          <NavMenu />
        </div>
        <div className="sticky-header">
          <NavMenu />
        </div>
        <ButtonToTop />
      </header>
    )
  }
}

const mapStateToProps = state => {
  return { state: state.home }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ onLoad: homeActions.onLoadSearch }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderLayoutSearch)
