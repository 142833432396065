/* eslint-disable no-undef */
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { navigate } from 'gatsby'

import Layout from '../components/layout-search'
import SEO from '../components/seo'
import Loading from '../components/loading'
import ShareModal from '../components/popup/share'
import View from '../components/presentation/search'
import { BoxGray } from '../components/template/box'
import { SearchForm2 } from '../components/presentation/manage-event/share'

import authActions from '../state/auth/actions'
import homeActions from '../state/home/actions'

class PublicListPage extends React.Component {
  state = {
    keyword: '',
    keywordType: 'title_en',
    isLoading: false,
  }

  constructor(props) {
    super(props)
    this.onGoUpcoming = this.onGoUpcoming.bind(this)
    this.onGoJoined = this.onGoJoined.bind(this)
    this.onGoHistory = this.onGoHistory.bind(this)
    this.onGoPublic = this.onGoPublic.bind(this)
  }

  componentDidMount() {
    $(window).on('scroll', () => {
      this.headerStyle()
    })

    this.props.checkAuthorization(null, '/', null, {
      onSuccess: () =>
        this.props.onLoad({
          search: {
            keyword: '',
            keywordType: '',
          },
          cb: () => {},
        }),
    })
  }

  componentWillUnmount() {
    this.props.onLoad({
      search: {
        keyword: '',
        keywordType: '',
      },
      cb: () => {},
    })
  }

  headerStyle() {
    if ($('.search-box-text').length) {
      const windowpos = $(window).scrollTop()
      const searchBox = $('.search-box-text')

      if (windowpos >= 220) {
        searchBox.css('visibility', 'visible')
        searchBox.css('opacity', '1')
        searchBox.css('top', '20px')
      } else if (windowpos >= 30) {
        searchBox.css('visibility', 'hidden')
        searchBox.css('opacity', '0')
      } else {
        searchBox.css('visibility', 'visible')
        searchBox.css('opacity', '1')
        searchBox.css('top', '35px')
      }
    }
  }

  onChangeKeyword = e => this.setState({ keyword: e.currentTarget.value })
  onChangeKeywordType = e =>
    this.setState({ keywordType: e.currentTarget.value })
  onSubmit = () => {
    this.setState({ isLoading: true }, () => {
      this.props.onLoad({
        search: {
          keyword: this.state.keyword,
          keywordType: this.state.keywordType,
        },
        cb: () => {
          this.setState({ isLoading: false })
        },
      })
    })
  }

  onKeyPress = event => {
    if (event.key === 'Enter') {
      this.onSubmit()
    }
  }

  onGoUpcoming() {
    navigate(`/upcoming`)
  }

  onGoJoined() {
    navigate(`/joined`)
  }

  onGoHistory() {
    navigate(`/history`)
  }

  onGoPublic() {
    navigate(`/public-list`)
  }

  reArrayCardData(dataList) {
    const newList = []
    if (dataList.length > 0) {
      dataList.forEach(v => {
        newList.push(...v)
      })
    }
    return newList
  }

  render() {
    if (!this.props.state.readyPageLogin || this.state.isLoading) {
      return <Loading />
    }
    return (
      <div>
        <div className="search-box-text">
          <SearchForm2
            keyword={this.state.keyword}
            keywordType={this.state.keywordType}
            onKeyword={this.onChangeKeyword}
            onKeywordType={this.onChangeKeywordType}
            onSearch={this.onSubmit}
            onKeyPress={this.onKeyPress}
          />
        </div>
        <Layout auth={this.props.auth.currentUser !== null}>
          <SEO keywords={[`invitree`]} />
          <BoxGray>
            <View
              onGoUpcoming={this.onGoUpcoming}
              onGoJoined={this.onGoJoined}
              onGoHistory={this.onGoHistory}
              onGoPublic={this.onGoPublic}
              onShare={this.props.onShare}
              onGoDetail={this.props.onGoDetail}
              // keyword={this.state.keyword}
              // keywordType={this.state.keywordType}
              // onChangeKeyword={this.onChangeKeyword}
              // onChangeKeywordType={this.onChangeKeywordType}
              // onSubmit={this.onSubmit}
              data={{
                upcoming_list: this.props.state.listUpcoming,
                upcoming_list_mobile: this.reArrayCardData(
                  this.props.state.listUpcoming
                ),
                joined_list: this.props.state.listJoined,
                joined_list_mobile: this.reArrayCardData(
                  this.props.state.listJoined
                ),
                history_list: this.props.state.listHistory,
                history_list_mobile: this.reArrayCardData(
                  this.props.state.listHistory
                ),
                public_list: this.props.state.listPublic,
                public_list_mobile: this.reArrayCardData(
                  this.props.state.listPublic
                ),
              }}
              locale={this.props.app.locale[this.props.app.lang]}
            />
          </BoxGray>
          <ShareModal
            id={this.props.state.selectedShareID}
            locale={this.props.app.locale[this.props.app.lang]}
          />
        </Layout>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    app: state.app,
    state: state.home,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      checkAuthorization: authActions.checkAuthorization,
      onShare: homeActions.onShare,
      onGoDetail: homeActions.onGoDetail,
      onLoad: homeActions.onLoadSearch,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicListPage)
