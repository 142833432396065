import React from 'react'

import PropTypes from 'prop-types'
import Header from './template/header-search'
import Footer from './template/footer'
import './layout.css'

const Layout = ({ auth, children }) => (
  <div className="page-wrapper">
    <Header auth={auth} />
    {children}
    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
